/*Button Style*/
button,
a:hover,
a:focus,
a:visited {
    text-decoration: none;
    outline: none !important;
}

.theme-btn {
  position: relative;
  transition: all 300ms ease;
}

.btn-style {
    position: relative;
    padding: 10px 30px;
    font-size: 16px;
    line-height: 30px;
    color: #ffffff;
    font-weight: 400;
    text-align: center;
    background-color: #695497;
    border-radius: 10px;
  }
  
  .btn-style .btn-title {
    position: relative;
  }
  
  .btn-style:hover {
    color: #695497!important;
    -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
    -ms-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
    -o-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
    
  }
  
  .btn-style:before {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 110%;
    border-radius: 9px;
    content: "";
    color: #695497!important;
    background-color: rgb(255, 255, 255);
    -webkit-transform: scale(0, 1);
    -ms-transform: scale(0, 1);
    transform: scale(0, 1);
    -webkit-transform-origin: top right;
    -ms-transform-origin: top right;
    transform-origin: top right;

    /* easeInOutQuint */
    -webkit-transition: transform 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -moz-transition: transform 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -o-transition: transform 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    transition: transform 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);

    /* easeInOutQuint */
    -webkit-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -moz-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -o-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
    transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);

  }
  
  .btn-style:hover:before {
    color: #695497!important;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
    -webkit-transform-origin: bottom left;
    -ms-transform-origin: bottom left;
    transform-origin: bottom left;
  }